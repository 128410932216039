import React from "react";
import { useTranslation } from "next-i18next";
import { StyledContainer } from "styles/basic.styled";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { StyledFooter, StyledFooterText, StyledDocLInk } from "./auth-footer.styled";

export const AuthFooter: React.FC = () => {
    const [__] = useTranslation();

    const [footerText, documents, footerText2] = __("auth.risk-warning").split("{sep}");

    return (
        <StyledFooter>
            <StyledContainer>
                <StyledFooterText variant={TypographyTypes.SMALL}>
                    {footerText}
                    <StyledDocLInk href="https://www.amega.finance/documents/Risk_Disclosure.pdf" target="_blank">{documents}</StyledDocLInk>
                    {footerText2}
                </StyledFooterText>
            </StyledContainer>
        </StyledFooter>
    );
};
