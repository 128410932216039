import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Typography } from "components/atoms/typography/typography.component";
import { Icon } from "components/atoms/icon/icon.component";

const defaultButtonCSS = css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    background-color: ${({theme}) => theme.gray?.[100]};
`;

export const StyledHeader = styled.header`
    position: relative;
    width: 100%;
    z-index: 2;
`;

export const StyledHeaderBox = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(16)};
`;

export const StyledLogo = styled(Icon)`
    width: 40px;
    min-width: 40px;
    height: 41px;
    fill: ${({theme}) => theme.green?.[800]};
`;

export const StyledTheme = styled.button`
    ${defaultButtonCSS};
    width: 36px;
    height: 36px;
    border-radius: 50%;
`;

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledThemeSVG = styled(Icon)`
    width: 20px;
    min-width: 20px;
    height: 20px;
    stroke: ${({theme}) => theme.text?.default};
    fill: ${({theme}) => theme.text?.default};
`;

export const StyledLanguageButton = styled.button`
    ${defaultButtonCSS};
    gap: 8px;
    border-radius: 24px;
    padding: ${spacing(8)};
`;

export const StyledArrowSVG = styled(Icon)`
    width: 16px;
    min-width: 16px;
    height: 16px;
    stroke: ${({theme}) => theme.text?.muted};
`;

export const StyledText = styled(Typography)`
    color: ${({theme}) => theme.text?.default};
`;

export const StyledCountrySVG = styled.svg`
    width: 20px;
    height: 20px;
    border-radius: 50%;
`;
