import React from "react";
import { IconTypes } from "components/atoms/icon/icon.types";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { useLanguage } from "components/providers/language/language.provider";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import { useModals } from "components/providers/modals/modals.provider";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledHeader,
    StyledHeaderBox,
    StyledLogo,
    StyledLanguageButton,
    StyledArrowSVG,
    StyledText,
    StyledTheme,
    StyledThemeSVG,
    StyledContent,
    StyledCountrySVG,
} from "./auth-header.styled";

export const AuthHeader: React.FC = () => {
    const language = useLanguage();
    const { openLanguageModal} = useModals();
    const theme = useTheme();

    return (
        <StyledHeader>
            <StyledHeaderBox>
                <CustomLink href="/">
                    <StyledLogo icon={IconTypes.LOGO} />
                </CustomLink>
                <StyledContent>
                    <StyledTheme onClick={ () =>
                        theme.setThemeVariant(
                            theme.themeVariant === ThemeVariant.Default
                            ?
                                ThemeVariant.Dark
                            :
                                ThemeVariant.Default
                        )
                    }>
                        <StyledThemeSVG icon={
                            theme.themeVariant === ThemeVariant.Default ? IconTypes.THEME_DEFAULT : IconTypes.THEME_DARK
                        } />
                    </StyledTheme>
                    <StyledLanguageButton onClick={openLanguageModal}>
                        <StyledCountrySVG>
                            <use xlinkHref={`/svg/icons-country.svg#${language.currentLocale}`} />
                        </StyledCountrySVG>
                        <StyledText variant={TypographyTypes.H5}>{language.currentLocale}</StyledText>
                        <StyledArrowSVG icon={IconTypes.ARROW} />
                    </StyledLanguageButton>
                </StyledContent>
            </StyledHeaderBox>
        </StyledHeader>
    );
};
