import React from "react";
import Head from "next/head";
import { useTranslation } from "react-i18next";

interface IProps {
    pathname: string;
}

interface ISeoProps {
    description?: string;
    title?: string;
}

export const SEO: React.FC<IProps> = ({ pathname }) => {
    const [__] = useTranslation();

    const map = new Map<string, ISeoProps>([
        [
            "default",
            {
                title: __("seo.title"),
                description: __("seo.description"),
            },
        ],
    ]);

    const currentPath = map.has(pathname)
        ? map.get(pathname)
        : map.get("default");

    return (
        <Head>
            <title>{currentPath?.title}</title>
            <meta name="description" content={currentPath?.description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={currentPath?.title} />
            <meta
                property="og:description"
                content={currentPath?.description}
            />
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:title" content={currentPath?.title} />
            <meta
                property="twitter:description"
                content={currentPath?.description}
            />
        </Head>
    );
};
