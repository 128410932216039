import styled from "styled-components";
import { media } from "lib/styled-components/variables";

export const StyledAuthBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContentArea = styled.div<{$container?: boolean}>`
    flex-grow: 1;
    max-width: ${({ $container }) => $container ? "100%" : "570px"};
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
`;

export const StyledContentSection = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${media.sm`
        justify-content: center;
    `};
`;

export const StyledImageBg = styled.div`
    position: relative;
    width: 700px;
    display: none;
    overflow: hidden;
    background-color: ${({ theme }) => theme.purple[800]};
    ${media.lg`
        display: block;
    `};
`;

export const StyledAuthImage = styled.div`
    position: absolute;
    bottom: -95px;
    left: 50%;
    transform: translate(-50% , 0);
    width: 800px;
    height: 963px;
`;

export const StyledAuthLayout = styled.div<{$container?: boolean, $dark: boolean}>`
    display: grid;
    position: relative;
    z-index: 1;
    gap: 20px;
    flex: 1;
    color: ${({ theme }) => theme.text?.default};
    background-color: ${({ theme }) => theme.bg?.page};
    ${media.lg<{$container?: boolean}>`
        grid-template-columns: ${({ $container }) => $container ? "1fr 700px" : "1fr"};
    `};
`;
