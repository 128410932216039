import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Typography } from "components/atoms/typography/typography.component";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";

export const StyledFooter = styled.footer`
    padding: ${spacing(16)} 0;
`;

export const StyledFooterText = styled(Typography)`
    color: ${({theme}) => theme.gray?.[700]};
`;

export const StyledDocLInk = styled(CustomLink)`
    color: ${({ theme}) => theme.green?.[800]};
`;
