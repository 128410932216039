import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { SEO } from "components/molecules/seo/seo.component";
import { AuthHeader } from "../auth-header/auth-header.component";
import { AuthFooter } from "../auth-footer/auth-footer.component";
import {
    StyledContentArea,
    StyledAuthLayout,
    StyledContentSection,
    StyledImageBg,
    StyledAuthBox,
    StyledAuthImage,
} from "./authorization-layout.styled";

export interface IProps extends React.PropsWithChildren {
    isFooter?: boolean;
    image?: string;
    container?: boolean;
}

export const AuthorizationLayout: React.FC<IProps> = ({
    children,
    isFooter = true,
    image,
    container,
}) => {
    const { pathname } = useRouter();
    const theme = useTheme();
    const [ hasMounted, setHasMounted ] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    return (
        <>
            {hasMounted &&
            <>
                <SEO pathname={pathname} />
                <StyledAuthLayout
                    $container={!container}
                    $dark={theme.themeVariant === ThemeVariant.Dark}
                >
                    <StyledAuthBox>
                        <AuthHeader />
                        <StyledContentArea $container={container}>
                            <StyledContentSection>
                                {children}
                            </StyledContentSection>
                            {isFooter && <AuthFooter />}
                        </StyledContentArea>
                    </StyledAuthBox>
                    {!container &&
                        <StyledImageBg>
                            <StyledAuthImage>
                                <Image
                                    src={image ? image : "/images/auth/register-bg.webp"}
                                    alt="Authorization image"
                                    layout="fill"
                                />
                            </StyledAuthImage>
                        </StyledImageBg>
                    }
                </StyledAuthLayout>
            </>
            }
        </>
    );
};
